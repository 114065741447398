
export const defaultURL = () => {

        if (window.location.href.indexOf('malta') > 0) {
                return 'https://malta.timehive.kr/';
        }

          return 'https://malta.timehive.kr/';
        // return 'http://3.37.150.71:8080/';

}
export default defaultURL
