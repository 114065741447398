import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import AuthRoute from './AuthRoute';
import { BrowserView, MobileView, isBrowser } from 'react-device-detect'

import 'moment/locale/ko'
import MobileHome from '../src/views//home/MobileHome.js'
import LoginManager from "../src/views/object/LoginManager"
import {LocalStorageGetData, LocalStorageSetData} from "../src/views/object/StorageManager"
import { checkUpdateAccessToken } from '../src/views/object/AxiosInstance';

const contract_type = LocalStorageGetData('contract_type');

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const Images = React.lazy(() => import('./views/home/Images'));


const updateAccessTokenKey = 'checkUpdateAccessToken';

class App extends Component {
  render() {
    if(LoginManager().accessToken && window.localStorage.getItem('data') && contract_type != -1){
      const value = LocalStorageGetData(updateAccessTokenKey);
      if(value==2){
      }else{
        checkUpdateAccessToken();
        return;
      }
    }else{
      if(document.location&&document.location.hash&&document.location.hash.endsWith('login')){
      }else{
        document.location.href = '#/login';
        // return;
      }
    }
    return (
      <HashRouter>
      {
        isBrowser ? (
          <BrowserView>
            <Suspense fallback={loading}>
              <Routes>
                <Route exact path="/login" element={<Login/>} />
                <Route exact path="/register" element={<Register/>} />
                <Route exact path="/404"  element={<Page404/>} />
                <Route exact path="/500"  element={<Page500/>} />
                <Route exact path="/images" element={<Images/>} />
                <Route path="/*" element= {<DefaultLayout/>} />                
              </Routes>
            </Suspense>
          </BrowserView>
        ) : (
          <MobileView>
            <MobileHome />
          </MobileView>
        )
      }
    </HashRouter>
    )
  }
}

export default App
