
import LoginManager from "../object/LoginManager"

export const LoginSetting = (userID, clubID, accessToken, refreshToken, defaultURL) => {

  var param = {}
  param.userID = userID;
  param.clubID = clubID;
  if (accessToken && accessToken.length > 0) {
    param.accessToken = accessToken;
  } else {
    param.accessToken = LoginManager().accessToken;
  }
  if (refreshToken && refreshToken.length > 0) {
    param.refreshToken = refreshToken;
  } else {
    param.refreshToken = LoginManager().refreshToken;
  }
  if (defaultURL && defaultURL.length > 0) {
    param.defaultURL = defaultURL;
  } else {
    param.defaultURL = LoginManager().defaultURL;
  }

  var paramString = JSON.stringify(param);
  var param64 = btoa(paramString);

  var paramReverse = param64.split("").reverse().join("");

  // var param64r = paramReverse.split("").reverse().join("");

  // var paramStringr = atob(param64);


  window.localStorage.setItem("data", paramReverse)

  //alert(paramString+" "+param64+" "+paramReverse+" "+param64r+" "+paramStringr);
}

export const LocalStorageSetData = (key, dataSet) => {
  var param = {};


  var paramReverse = window.localStorage.getItem('cookie');
  if (paramReverse && paramReverse.length > 0) {
    var param64 = paramReverse.split("").reverse().join("");
    var paramString = atob(param64);
    if (paramString && paramString != undefined) {
      var decodeParam = decodeURI(paramString);
      param = JSON.parse(decodeParam);
    }
  }

  param[key] = dataSet;

  var paramString = JSON.stringify(param);
  var encodeParam = encodeURI(paramString);
  var param64 = btoa(encodeParam);

  paramReverse = param64.split("").reverse().join("");

  // var param64r = paramReverse.split("").reverse().join("");

  // var paramStringr = atob(param64);


  window.localStorage.setItem("cookie", paramReverse)

  //alert(paramString+" "+param64+" "+paramReverse+" "+param64r+" "+paramStringr);
}
export const LocalStorageGetData = (key) => {

  const paramReverse = window.localStorage.getItem('cookie');

  if (paramReverse && paramReverse.length > 0) {
    var param64 = paramReverse.split("").reverse().join("");
    var paramString = atob(param64);
    var decodeParam = decodeURI(paramString);
    var param = JSON.parse(decodeParam);
    return param[key];
  } else {
    return null;
  }
}

export default LoginSetting
