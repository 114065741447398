import React, { Component } from 'react'
import {
    CContainer,
    CCard,
    CCol,
    CRow,
    CCardBody,
    CImage,
    CForm
} from '@coreui/react'
class MobileHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reservationList: [],
            questionList: [],
            selectedQuestionInfo: null
        }
    }
    componentDidMount() {
    }


    render() {
        return (
            <div className="c-app c-default-layout flex-row align-items-center" style=
                {{ backgroundColor: "#123456" }}>
                <CContainer>
                    <CRow className="justify-content-center">
                        <CCol md="4">
                            <CCard className="p-6">
                                <CCardBody>
                                    <CForm>
                                        <CImage col="6" src={'avatars/logo_blue.png'}
                                            className="c-avatar-img">

                                        </CImage>
                                        <h4 style={{ textAlign: 'center', color: '#234567' }}>
                                            모바일은 지원하지 않습니다.<br />
                                            말타 매니저 앱을 다운받아주세요.<br /><br />
                                        </h4>
                                        <CRow style={{ padding: '5px' }}>
                                            <CCol col='6' style={{ margin: '5px' }}><CImage onClick={() => document.location.href = 'https://apps.apple.com/app/id1532008089'} width='100%' col='6' src={'avatars/appstore.png'}></CImage></CCol>
                                            <CCol col='6' style={{ margin: '5px' }}><CImage onClick={() => document.location.href = 'https://play.google.com/store/apps/details?id=com.timehive.marlta_coach'} width='100%' col='6' src={'avatars/playstore.png'}></CImage></CCol>
                                        </CRow>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </CContainer>
            </div>
        )
    }
}
export default MobileHome