import axios from "axios";
import LoginManager from "../object/LoginManager"
import DefaultURL from "./Default";
import moment from "moment";
import CryptoJS from "crypto-js";
import { osName, osVersion, browserName, fullBrowserVersion, isMacOs, isChrome,isFirefox, isSafari, isOpera, isIE, isEdge,isYandex,isChromium,isMobileSafari, isSamsungBrowser    } from 'react-device-detect'
import { v4 as uuidv4 } from 'uuid';
import { LoginSetting, LocalStorageGetData, LocalStorageSetData } from "./StorageManager";

const appVersion = '1.3.0'
const updateAccessTokenKey = 'checkUpdateAccessToken';
/*
const axiosInstance = axios.create({
  header: {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'os':3,
    'accesstoken2':accessToken2(),  
    'user_id':''
  },
  baseURL:DefaultURL()
})
*/
function axiosInstance(category) {
  const instance = axios.create({
    headers: {
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'os': isMacOs ? 4 : 3,
      'accesstoken2': accessToken2(category),
      'user-id': LoginManager().userID,
      'os-version': osName + '_' + osVersion,
      'device-uuid': UUID(),
      'release-type': releaseType(),
      'app-version': appVersion,
      'app-type':3
    },
    baseURL: DefaultURL()
  })

  if (category != "webclub/login") {

    instance.interceptors.response.use(res => {
      if (res.data.status == 0) {
        if (res.data.errorCode == 302) {
          delete res.data.message;
          // res.data.message = '토큰 재발급으로 화면을 새로 갱신합니다.';
          getNewAccessToken();
        } else if (res.data.errorCode == 102) {
          // alert(JSON.stringify(res.data))
        } else if (!res.data.message) {
          res.data.message = '알 수 없는 에러 [' + res.data.errorCode + ']';
        }
      }
      return res;
    })
  }


  return instance;
}
export function axiosGet(category, data) {
  // return category+' is test code';
  // alert(UUID())
  return axiosInstance(category).get(category, data);
}
export function axiosPost(category, data) {
  // return category+' is test code';
  // alert(category)
  return axiosInstance(category).post(category, data);
}

export function axiosPut(category, data) {
  // return category+' is test code';
  // alert(category)
  return axiosInstance(category).put(category, data);
}

export function axiosDelete(category, data) {
  // return category+' is test code';
  // alert(category)
  return axiosInstance(category).delete(category, data);
}

export function checkUpdateAccessToken() {

  const category = "pass/check/update/accesstoken";
  // alert('test - 인터셉트 '+blankEncToken(category));
  const newInstance = axios.create({
    headers: {
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'os': isMacOs ? 4 : 3,
      'app-version': appVersion,
      'app-type': 3,
      'release-type': releaseType(),
      'os-version': osName + '_' + osVersion,
      'browser-type': browserType(),
      'browser-version': browserVersion(),
      'user-id': LoginManager().userID,
      'accesstoken2': blankEncToken(category),
      'device-uuid': UUID()
    },
    baseURL: DefaultURL()
  })

  newInstance.post(category, {
      userIdList: [LoginManager().userID]
  }).then(res => {
    // alert(JSON.stringify(res));
    if (res.data.status == 0) {
      if (res.data.errorCode == 307) {
        alert('기존에 로그인 되어 있던 계정은 토큰 유효기간이 만료하여 로그아웃되었습니다')
        logout();
      } else if (res.data.errorCode == 301) {        
        alert('컴퓨터 시간 값이 이상합니다 현재 시간으로 설정해주시기 바랍니다')
        checkUpdateAccessToken();
      } else if (res.data.errorCode == 300 || res.data.errorCode == 304) {
        if (res.data?.message) alert(res.data.message)
      } else {
        alert('알수 없는 오류 ('+res.data.errorCode+')');
        logout();
      }
    } else {
      // alert('토큰 재발급으로 화면을 새로 갱신합니다.')
      var newAuthTokenList = res.data.data.newAuthTokenList;
      if (newAuthTokenList?.length>0){
        var newToken = newAuthTokenList[0];
        LoginSetting(LoginManager().userID, LoginManager().clubID, newToken.access_token, newToken.refresh_token)
        LocalStorageSetData(updateAccessTokenKey, 2);
        window.location.reload();
      }else{
        alert('토큰 통신 오류로 재시도합니다[empty array)')
        LocalStorageSetData(updateAccessTokenKey, 0);
        checkUpdateAccessToken();
      }
    }

  }).catch(error => {

  })

}
function UUID() {
  var uuid = LocalStorageGetData('uuid');
  if (uuid && uuid.length > 0) {
    return uuid;
  } else {
    uuid = uuidv4();
    LocalStorageSetData('uuid', uuid);
    return uuid;
  }
}
function releaseType() {
  if (window.location.href.indexOf('malta') > 0) {
    return 'live';
  } else {
    return 'debug';
  }
}

function accessToken2(apiKey) {
  var token = LoginManager().accessToken;
  return encToken(token, apiKey);
}
function refreshToken2(apiKey) {
  var token = LoginManager().refreshToken;
  return encToken(token, apiKey);
}
const encToken = (token, apiKey) => {
  if (token && apiKey) {
    var keys = apiKey.split('/');
    var key = keys[keys.length - 1];
    const rkey = CryptoJS.SHA256(key);
    var dateStr = moment().utc().format('YYYYMMDDHHmmss');
    var encStr = encrypt(dateStr, rkey);
    var lengthStr = encStr.length.toString();

    while (lengthStr.length < 3) {
      lengthStr = '0' + lengthStr;
    }
    var token = token + encStr + lengthStr;

    return token;
  }
  return null;
}
const encrypt = (val, secretKey) => {
  const encrypted = CryptoJS.AES.encrypt(val, secretKey, {
    iv: CryptoJS.enc.Utf8.parse(""),
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC
  });
  return encrypted.toString();
}

function browserType() {
  if(isChrome) return 1;
  if(isFirefox) return 2;
  if(isSafari) return 3;
  if(isOpera) return 4;
  if(isIE) return 5;
  if(isEdge) return 6;
  if(isYandex) return 7;
  if(isChromium) return 8;
  if(isMobileSafari) return 9;
  if(isSamsungBrowser) return 10;
  if(browserName=='Whale') return 11;
  return 0;
}

function browserVersion() {
  if(browserType()==0){
    return browserName+'_'+fullBrowserVersion;
  }
  return fullBrowserVersion;
}

function getNewAccessToken() {

  const category = "pass/new/accessToken";
  const newInstance = axios.create({
    headers: {
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'os': isMacOs ? 4 : 3,
      'accesstoken2': accessToken2(category),
      'user-id': LoginManager().userID,
      'os-version': osName + '_' + osVersion,
      'device-uuid': UUID(),
      'release-type': releaseType(),
      'app-version': appVersion,
      'app-type': 3,
      'refreshtoken2': refreshToken2(category),
      'browser-type': browserType(),
      'browser-version': browserVersion()
    },
    baseURL: DefaultURL()
  })

  newInstance.get(category, {
    params: {
      user_id: LoginManager().userID
    }
  }).then(res => {
    // alert(JSON.stringify(res));
    if (res.data.status == 0) {
      if (res.data.errorCode == 303) {
        alert('기존에 로그인 되어 있던 계정은 토큰 유효기간이 만료하여 로그아웃되었습니다')
        logout();
      } else if (res.data.errorCode == 301) {
        alert('컴퓨터 시간 값이 이상합니다 현재 시간으로 설정해주시기 바랍니다')
        getNewAccessToken();
      } else if (res.data.errorCode == 300 || res.data.errorCode == 304) {
        if(res.data?.message)alert(res.data.message)
      }
    } else {
      // alert('토큰 재발급으로 화면을 새로 갱신합니다.')
      LoginSetting(LoginManager().userID, LoginManager().clubID, res.data.data.access_token, res.data.data.refresh_token)
      window.location.reload();
    }

  }).catch(error => {

  })

}



const blankEncToken = (apiKey) => {
  if (apiKey) {
    var keys = apiKey.split('/');
    var key = keys[keys.length - 1];
    const rkey = CryptoJS.SHA256(key);
    var dateStr = moment().utc().format('YYYYMMDDHHmmss');
    var encStr = encrypt(dateStr, rkey);
    var lengthStr = encStr.length.toString();

    while (lengthStr.length < 3) {
      lengthStr = '0' + lengthStr;
    }
    var token = encStr + lengthStr;

    return token;
  }
  return null;
}

const logout = () => {
  window.localStorage.setItem("data", "")
  window.localStorage.setItem("club_name", "")
  window.sessionStorage.setItem("selectedDate", "")
  // window.location.reload();
  document.location.href = '#/login'
  window.location.reload();
}
export default axiosInstance;