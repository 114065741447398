
export const LoginManager = () => {
  const paramReverse = window.localStorage.getItem('data');
  if (paramReverse && paramReverse.length > 0) {
    var param64 = paramReverse.split("").reverse().join("");
    var paramString = atob(param64);
    return JSON.parse(paramString);
  } else {
    return {};
  }
}
export default LoginManager
